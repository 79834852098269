import axios from "axios"
import router from './router'
import store from './store'


export default () => {

  router.onReady(() => {
    const anteriorDB = localStorage.getItem("KEY") || 0
    store.state.DATA_BASE = router.currentRoute.params.database
    localStorage.setItem("KEY", store.state.DATA_BASE)
    store.state.CARRITO = JSON.parse(localStorage.getItem("CARRITO" + store.state.DATA_BASE) || '[]')
    if (localStorage.getItem("CLIENTE")) {
      if (anteriorDB != store.state.DATA_BASE) {
        localStorage.removeItem('CLIENTE')
      } else {
        store.state.CLIENTE = JSON.parse(window.atob(localStorage.getItem("CLIENTE")))
      }
    } else {
      store.state.CLIENTE = {
        ciudad: {
          departamento: {}
        }
      }
    }
    axios.interceptors.request.use(config => {
      config.headers.database = store.state.DATA_BASE
      return config
    }, err => {
      return Promise.reject(err) - l
    })

    if (router.currentRoute.name !== 'home') {
      axios.get('/go-empresa').then(({ data }) => {
        // console.log(data);
        if (data.serial) {
          router.push(`/${store.state.DATA_BASE}/fuera-servicio`)
        } else {
          store.state.EMPRESA = data
        }
      }).catch((err) => {
        console.dir(err);
      });
    }
  })
}