<template lang="pug">
div#pie_pagina
  footer.bg-success.py-3.d-md-block.fixed-bottom(:class="{'d-none':!isHome}")
    .container
      .row
        .col-12.text-white-50.text-center
          p.m-0 {{ current_year }} &copy; by SKALA Platform
          p.m-0:  a.text-white(href='https://skala.one' target='_blank') www.skala.one
          //- img.img-fluid.mt-3(src='/assets/img/Logo-SkGo.png' alt)          

  //- Menu Mobile
  .bg-success.w-100.d-block.d-md-none.d-lg-none.mobile-nav-bottom.position-fixed.d-flex.align-items-center.justify-content-around.shadow-sm(v-if="!isHome")
    router-link(:to='`/${$store.state.DATA_BASE}/busqueda`')
      span.bi.bi-search
      |Buscar
    router-link(:to='`/${$store.state.DATA_BASE}/`')
      img.img-fluid.mx-auto.w-75(src='/assets/img/Logo-SkGo-Orange.png' alt='#')      
    a(v-if='!CLIENTE.id' data-bs-toggle='modal' href='#mdLogin')
      span.bi.bi-unlock-fill
      |Ingresar
    router-link(v-else :to='`/${$store.state.DATA_BASE}/micuenta`')
      span.bi.bi-person-fill
      |Mi Cuenta
      //- span.bi.bi-house-door-fill
      //- |Inicio    
      //- router-link(:to='`/${$store.state.DATA_BASE}/carrito`')
        span.bi.bi-cart3
        |Carrito
        b(v-if='CARRITO.length') {{ CARRITO.length }}  
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    current_year: new Date().getFullYear(),
    isHome: false
  }),

  computed: {
    ...mapState(['CARRITO', 'CLIENTE'])
  },

  created() {
    this.current_year = new Date().getFullYear()
    this.isHome = this.$route.name == 'home'
  }
}
</script>