<template lang="pug">
.modal.fade#mdLogin(data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true')
  .modal-dialog.modal-lg.modal-dialog-centered.login-popup-main
    .modal-content.border-0.shadow.overflow-hidden.rounded
      .modal-body.p-0
        .login-popup
          button#cerrar_login.btn-close.position-absolute(type='button' data-bs-dismiss='modal' aria-label='Close')
          .row.g-0
            .d-none.d-md-flex.col-md-4.col-lg-4.bg-image1
            .col-md-8.col-lg-8.py-lg-5
              .login.p-4
                .mb-4
                  h5.mb-0.fw-bold Facturar a
                  p.text-muted.mb-0 Por favor ingrese con su numero de identificacion y contraseña
                form
                  .input-group-sm.input-group.bg-white.border.rounded.mb-3.p-2
                    span.input-group-text.bg-white.border-0: i.bi.bi-person-badge
                    input.form-control.bg-white.border-0.ps-0(type='text' placeholder='Numero de Identificacion' v-model='identificacion' @blur="verificarExistencia()")
                    span(v-if='existe').input-group-text.bg-white.border-0.text-success: i.bi.bi-check-circle-fill
                  .input-group-sm.input-group.bg-white.border.rounded.mb-3.p-2
                    span.input-group-text.bg-white.border-0: i.bi.bi-key-fill
                    input.form-control.bg-white.border-0.ps-0(type='password' placeholder='Contraseña' v-model='contrasenia' @keyup.enter="login()")
                  .row
                    .col-6: a.text-decoration-none.text-success.small(href='#') Olvidé mi contraseña
                    .col-6.text-end ¿No tienes cuenta?
                      a.text-decoration-none.text-success.small.ms-1(data-bs-toggle='modal' href='#mdSignup') Regístrate
                  .row
                    .col-8
                      button.btn.btn-success.btn-md.py-2.text-uppercase.w-100.mt-4(type='button' v-if="procesando")
                        .spinner-border(role='status')
                          span.visually-hidden Procesando...
                      button.btn.btn-success.btn-md.py-2.text-uppercase.w-100.mt-4(v-else type="button" @click="login()") Continuar
                        i.bi.bi-arrow-right.ms-2
                    .col-4
                      button.btn.btn-md.btn-danger.py-2.w-100.mt-4(type='button' data-bs-dismiss='modal' aria-label='Close') Salir
</template>

<script>
export default {
  data: () => ({
    identificacion: '',
    contrasenia: '',
    procesando: false,
    existe: false
  }),

  methods: {
    login() {
      if (!this.identificacion.trim().length) {
        this.$store.commit('MSG', 'Digite numero de identificacion')
        return
      }
      if (!this.contrasenia.trim().length) {
        this.$store.commit('MSG', 'Digite contraseña')
        return
      }
      this.procesando = true
      this.axios.post('/go-empresa/login', {
        identificacion: this.identificacion,
        contrasenia: this.contrasenia
      }).then(({ data }) => {
        this.procesando = false
        // console.log(data);
        if (data.mensaje) {
          this.$store.commit('MSG', data.mensaje)
        } else {
          this.$store.commit('SET_CLIENTE', data.cliente)
          document.getElementById('cerrar_login').click()
          this.identificacion = ''
          this.contrasenia = ''
          this.existe = false
          if (data.change_password) {
            this.$store.commit('MSG', 'Para mas seguridad, recomendamos cambiar su contraseña')
          }

          // Service Worker Support
          if ("serviceWorker" in navigator) {
            registerServiceWorker({
              customer: data.cliente.id,
              data_base: parseFloat(this.$store.state.DATA_BASE)
            }, process.env.VUE_APP_NOTIFICATION_API).catch(err => console.log(err));
          }
        }
      })
    },

    verificarExistencia() {
      if (this.identificacion.trim().length) {
        this.axios.get('/go-empresa/tercero-documento/' + this.identificacion.trim()).then(({ data }) => {
          this.existe = data.id > 0
        })
      } else {
        this.existe = false
      }
    }
  }
}
</script>