<template lang="pug">
.modal.fade#mdSignup(data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true')
  .modal-dialog.modal-lg.modal-dialog-centered.login-popup-main
    .modal-content.border-0.shadow.overflow-hidden.rounded
      .modal-body.p-0
        .login-popup
          button#cerrar_signup.btn-close.position-absolute(type='button' data-bs-dismiss='modal' aria-label='Close')
          .row.g-0
            .d-none.d-md-flex.col-md-4.col-lg-4.bg-image1
            .col-md-8.col-lg-8.py-lg-5
              .login.p-4
                .mb-4
                  h5.mb-0.fw-bold Nuevo Cliente
                  p.text-muted.mb-0 Por favor diligencie la siguiente informacion
                  p.text-muted.mb-0 Los campos marcados con  #[span.text-danger *] son obligatorios
                form
                  .row
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Identificacion #[span.text-danger *]
                      input.form-control(type='text' v-model='tercero.identificacion')
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Contraseña #[span.text-danger *]
                      input.form-control(type='password' v-model='tercero.password')
                  .row
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Nombre #[span.text-danger *]
                      input.form-control(type='text' v-model='tercero.nombre')
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Apellido #[span.text-danger *]
                      input.form-control(type='text' v-model='tercero.apellido')
                  .row
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Telefono #[span.text-danger *]
                      input.form-control(type='text' v-model='tercero.telefonos')
                    .cols-12.col-md-6.mb-3
                      label.form-label.mb-0 Email
                      input.form-control(type='email' v-model='tercero.email')
                  .mb-3
                    label.form-label.mb-0 Direccion #[span.text-danger *]
                    input.form-control(type='text' v-model='tercero.direccion')
                  .row
                    .col-8
                      button.btn.btn-success.btn-lg.py-2.text-uppercase.w-100.mt-4(type='button' v-if="procesando")
                        .spinner-border(role='status')
                          span.visually-hidden Procesando...
                      button.btn.btn-success.btn-lg.py-2.text-uppercase.w-100.mt-4(v-else type="button" @click="registarCliente()") Registrarme
                        i.bi.bi-arrow-right.ms-2
                    .col-4
                      button.btn.btn-md.btn-danger.py-2.w-100.mt-4(type='button' data-bs-dismiss='modal' aria-label='Close') Salir
</template>

<script>
export default {
  data: () => ({
    procesando: false,
    tercero: {
      identificacion: '',
      password: '',
      nombre: '',
      apellido: '',
      direccion: '',
      telefonos: '',
      email: ''
    },
    terceroDefault: {
      identificacion: '',
      password: '',
      nombre: '',
      apellido: '',
      direccion: '',
      telefonos: '',
      email: ''
    }
  }),

  methods: {
    registarCliente() {
      if (!this.tercero.identificacion.trim().length || !this.tercero.password.trim().length ||
        !this.tercero.nombre.trim().length || !this.tercero.apellido.trim().length ||
        !this.tercero.telefonos.trim().length || !this.tercero.direccion.trim().length) {
        this.$store.commit('MSG', 'complete los campos obligatorios')
        return
      }
      this.procesando = true
      this.axios.post('/go-empresa/registrar-cliente', this.tercero).then(({ data }) => {
        this.procesando = false
        // console.log(data);
        if (data.ok) {
          this.$store.commit('SET_CLIENTE', data.cliente)
          document.getElementById('cerrar_signup').click()
          this.tercero = Object.assign({}, this.terceroDefault)
          this.$store.commit('MSG', 'Registro Exitoso')

          // Service Worker Support
          if ("serviceWorker" in navigator) {
            registerServiceWorker({
              customer: data.cliente.id,
              data_base: parseFloat(this.$store.state.DATA_BASE)
            }, process.env.VUE_APP_NOTIFICATION_API).catch(err => console.log(err));
          }
        } else {
          this.$store.commit('MSG', data.mensaje)
        }
      })
    }
  }
}
</script>