import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import handlerAuth from './handlerAuth'

Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_API_ROUTE;

Vue.config.productionTip = false

handlerAuth()

Vue.filter('formatNumber', function (num) {
  if (num) {
    num = num.toString().replace(/\,/g, '');
    const formateado = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(num);
    return '$' + formateado;
  }
  return "0";
})

Vue.filter('formatDate', function (fech, hr) {
  if (fech) {
    let meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    let fecha = new Date(fech);
    let dia = fecha.getDate();
    if (dia < 10) {
      dia = '0' + dia
    }
    let fecha_aux = dia + "/" + meses[fecha.getMonth()] + "/" + fecha.getFullYear();

    if (hr) {
      const hrs = new Date(hr);
      fecha_aux += ' ' + hrs.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    }
    return fecha_aux
  }
  return "";
})

Vue.filter('limitStr', function (cadena, limit) {
  if (cadena && cadena.length > limit) {
    return cadena.substr(0, limit);
  }
  return cadena;
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
