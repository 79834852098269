import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DATA_BASE: undefined,
    EMPRESA: {
      ciudad: {
        departamento: {}
      }
    },
    CARRITO: [],
    MSG: '',
    CLIENTE: {
      ciudad: {
        departamento: {}
      }
    }
  },
  getters: {
    sizeCarrito: (state) => {
      return state.CARRITO.reduce((total, currentItem) => total += currentItem.cantidad, 0);
    }
  },
  mutations: {
    MSG(state, msg) {
      state.MSG = msg
      const toast = new bootstrap.Toast(document.getElementById('msg'))
      toast.show()
    },

    SET_CLIENTE(state, payload) {
      state.CLIENTE = payload
      localStorage.setItem('CLIENTE', window.btoa(JSON.stringify(state.CLIENTE)))
    },

    SET_CARRITO(state, payload) {
      const { art, restar } = payload
      let item = {
        cantidad: 1,
        valor_unitario: art.valor_sugerido_venta || art.valor_unitario,
        valor_inicial: art.valor_sugerido_venta || art.valor_inicial,
        valor_parcial: art.valor_sugerido_venta || art.valor_parcial,
        tarifa_impuesto: art.tarifa_impuesto || 0,
        valorImpuesto: 0,
        vlr_descuento: 0,
        tipo_descuento: '',
        base_impuesto: art.valor_sugerido_venta || art.base_impuesto
      }
      if (art.articulo) {
        item.articulo = art.articulo
      } else {
        item.articulo = {
          id: art.id,
          codigo: art.codigo,
          descripcion_comercial: art.descripcion_comercial
        }
      }
      if (art.impuesto_generado) {
        item.tarifa_impuesto = art.impuesto_generado.tarifa
      }
      item.base_impuesto = item.valor_parcial / (1 + (item.tarifa_impuesto / 100))
      item.valorImpuesto = item.base_impuesto * (item.tarifa_impuesto / 100)

      if (restar) {
        item.cantidad = -1
      }
      const id_entrante = art.id || art.articulo.id
      let item_existe = state.CARRITO.find(it => it.articulo.id == id_entrante)
      if (item_existe) {
        item_existe.cantidad += item.cantidad
        item_existe.valor_parcial = item_existe.valor_unitario * item_existe.cantidad
        item_existe.base_impuesto = item_existe.valor_parcial / (1 + (item_existe.tarifa_impuesto / 100))
        item_existe.valorImpuesto = item.base_impuesto * (item_existe.tarifa_impuesto / 100)
        const index = state.CARRITO.findIndex(it => it.articulo.id == id_entrante)
        if (item_existe.cantidad <= 0) {
          state.CARRITO.splice(index, 1)
        } else {
          state.CARRITO.splice(index, 1, item_existe)
        }
      } else {
        state.CARRITO.push(item)
      }

      localStorage.setItem("CARRITO" + state.DATA_BASE, JSON.stringify(state.CARRITO))
    },

    QUIT_CARRITO(state, item) {
      const index = state.CARRITO.findIndex(it => it.articulo.id == item.articulo.id)
      state.CARRITO.splice(index, 1)
      localStorage.setItem("CARRITO" + state.DATA_BASE, JSON.stringify(state.CARRITO))
    },

    EMPTY_CARRITO(state) {
      state.CARRITO = []
      localStorage.setItem("CARRITO" + state.DATA_BASE, JSON.stringify(state.CARRITO))
    }
  },
  actions: {
  },
  modules: {
  }
})
