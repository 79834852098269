import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:database',
    name: 'categorias',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriasView.vue')
  },
  {
    path: '/:database/micuenta',
    name: 'micuenta',
    component: () => import(/* webpackChunkName: "about" */ '../views/MiCuentaView.vue')
  },
  {
    path: '/:database/productos-categoria/:categoria',
    name: 'productos-categoria',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductosGrupoView.vue')
  },
  {
    path: '/:database/carrito',
    name: 'carrito',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarritoView.vue')
  },
  {
    path: '/:database/confirma-pedido',
    name: 'confirma-pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmaOrdenView.vue')
  },
  {
    path: '/:database/busqueda',
    name: 'busqueda',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusquedaView.vue')
  },
  {
    path: '*',
    component: () => import('../views/404NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.name == 'micuenta'
  const loggedIn = localStorage.getItem('CLIENTE')

  if (authRequired) {
    if (!loggedIn) {
      return next('/' + to.params.database)
    }
  }
  next()
})

export default router
