<template lang="pug">
.modal.fade#add-delivery-location(tabindex='-1' aria-hidden='true')
  .modal-dialog.modal-dialog-centered
    .modal-content.border-0.shadow
      .modal-header.px-4
        h5.h6.modal-title.fw-bold Informacion del Lugar
        button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
      .modal-body.p-4
        .bg-light.rounded.overflow-hidden.border.mb-4
          //- iframe.border-0.w-100(src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7848.988814550481!2d-75.45209620000006!3d10.382250399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sco!4v1673717710595!5m2!1ses-419!2sco' height='150' allowfullscreen loading='lazy' referrerpolicy='no-referrer-when-downgrade')
          .bg-white.py-2(v-if='EMPRESA.imagen'): img.d-block.mx-auto(:src='`data:image/png;base64,${EMPRESA.imagen}`' alt='...')          
          .p-3
            .d-flex.align-items-center.gap-3
              i.bi.bi-geo-alt.h5
              span.text-start
                b.mb-0.h6.d-block.fw-bold Direccion
                small.text-muted.text-opacity-50 {{ EMPRESA.direccion }}
                small.d-block.text-muted.text-opacity-50 {{ EMPRESA.ciudad.nombre }} - {{ EMPRESA.ciudad.departamento.nombre }}
        .row
          .col-12.mb-2
            label.form-label.mb-0 Nombre
            .input-group
              span.input-group-text.bg-white.border-0: i.bi.bi-house-door-fill.text-muted
              p.form-control.form-control-plaintext {{ EMPRESA.razon_comercial }}
          .col-12.mb-2
            label.form-label.mb-0 Telefono
            .input-group
              span.input-group-text.bg-white.border-0: i.bi.bi-telephone-fill.text-muted
              p.form-control.form-control-plaintext {{ EMPRESA.telefonos }}
          .col-12.mb-2
            label.form-label.mb-0 Email
            .input-group
              span.input-group-text.bg-white.border-0: i.bi.bi-envelope-fill.text-muted
              p.form-control.form-control-plaintext.text-lowercase {{ EMPRESA.email }}
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({

  }),

  computed: {
    ...mapState(['EMPRESA'])
  }
}
</script>