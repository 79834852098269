<template lang="pug">
nav#cabecera.navbar.navbar-expand-lg.navbar-light.bg-white.sticky-top.shadow-sm.osahan-header.py-0
  .container    
    router-link.navbar-brand.me-4.d-none.d-md-block(v-if="!isHome" :to='`/${$store.state.DATA_BASE}`')
      img.img-fluid(src='/assets/img/Logo-SkGo.png' alt='#')
    a.navbar-brand.me-4(v-else): img.img-fluid(src='/assets/img/Logo-SkGo.png' alt='#')

    div.text-truncate(v-if="!isHome").text-start
      a.d-flex.text-dark.align-items-center.gap-2.text-decoration-none.bg-white.border-0.me-auto(href='#' data-bs-toggle='modal' data-bs-target='#add-delivery-location')
        i.bi.bi-geo-alt-fill.fs-5.text-success
        span
          b {{ EMPRESA.razon_comercial | limitStr(35) }}
          small.text-success.d-block {{ EMPRESA.direccion | limitStr(40) }}

    .d-md-none.d-block(v-if="!isHome")
      router-link.ms-auto.me-1.btn.btn-light.position-relative.rounded-pill.rounded-icon(:to='`/${$store.state.DATA_BASE}/carrito`')
        i.bi.bi-cart3
        span(v-if='CARRITO.length').position-absolute.top-0.start-100.translate-middle.badge.rounded-pill.bg-warning {{ $store.getters.sizeCarrito }}
        span.visually-hidden Cart

    .collapse.navbar-collapse#navbarSupportedContent.py-3.d-none.d-md-block(v-if="!isHome")      
      .d-flex.align-items-center.gap-2.ms-auto
        router-link.btn.btn-light.position-relative.rounded-pill.rounded-icon(:to='`/${$store.state.DATA_BASE}/busqueda`'): i.bi.bi-search
        router-link.btn.btn-light.position-relative.rounded-pill.rounded-icon(:to='`/${$store.state.DATA_BASE}/carrito`')
          i.bi.bi-cart3
          span(v-if='CARRITO.length').position-absolute.top-0.start-100.translate-middle.badge.rounded-pill.bg-warning {{ $store.getters.sizeCarrito }}
          span.visually-hidden Cart
        a(v-if='!CLIENTE.id' data-bs-toggle='modal' href='#mdLogin' role='button').btn.btn-success.rounded-pill.px-3.text-uppercase.ms-2 Ingresar
        router-link(v-else).btn.btn-success.rounded-pill.px-3.text-uppercase.ms-2(:to='`/${$store.state.DATA_BASE}/micuenta`')
          i.bi.bi-person-fill.me-1
          |Mi Cuenta
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    isHome: false
  }),

  created() {
    this.isHome = this.$route.name == 'home'
  },

  computed: {
    ...mapState(['EMPRESA', 'CARRITO', 'CLIENTE'])
  }
}
</script>